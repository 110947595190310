import React from "react";
import FaqForm from "./FaqForm";

const Faq = ({ data }) => {
  return (
    <div className="container fade-in">
      <div>
        <div className="list-primary">
          <h1 className="display-5 fs-m">FAQ</h1>
          {/* <h1 className="display-4 fs-s">FAQ</h1>
          <hr /> */}

          <FaqForm data={data} />
        </div>
      </div>
    </div>
  );
};

export default Faq;
