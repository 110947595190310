import React, { useState,useEffect } from "react";
import {getBlogs} from "../../actions/products";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
//import NewsAccordion from "./NewsMedia Accordion/NewsAccordion";
//import { MediaData } from "./MediaData";
import Mypagination from "./Mypagination";
import "./Blog.css";


const  Blog = (props) => {
  const { blogs} = props;

  useEffect(() => {
    props.getBlogs();
  }, [])

  const [showperpage,setShowperpage]=useState(4)

  const [pagination,setPagination]=useState({
    start:0,
    end:showperpage
  })
  const onPaginationChange =(start,end)=>{
    setPagination({start:start,end:end})
  }
  return (
    <div>      

      <div className="News">     
          
            <h1 className="display-4" style={{marginBottom:'1px'}}>Blogs</h1>
              
             {blogs.slice(pagination.start,pagination.end).map((item,index)=>{               
               item.bloglink = "/blog/description/?id=" + item.pkblogid
               const blogpath = item.bloglink.replace("?id=","")
               const blogdate =  item.blogdate.replace("T00:00:00","")
               return  <div className="Newsaccordion-primary-tabs"  style={{marginTop:'40px'}}>
               <div className="Newsaccordion-primary-tab">
                 <input className="Newsaccordion-primary-input" type="checkbox" checked="true" id={item.id} />
                 <label className="Newsaccordion-primary-tab-label" for={item.id}>
                 <Link className="NewsTitle"  to={blogpath}> {blogdate}</Link> 
                 </label>
                 <hr />
                 <div class="Newsaccordion-primary-tab-content">{item.title}</div>
               </div>
             </div>
             })}
        
              <Mypagination
               showperpage={showperpage} 
               onPaginationChange={ onPaginationChange }
               total ={blogs.length}
               />                  
      </div>
    </div>
  ); 
}

const mapStateToProps = (state) => {
  return {    
    blogs:state.data.blogs.refBlogs ? state.data.blogs.refBlogs : [],
  };
};

export default connect(mapStateToProps, { getBlogs })(Blog);