import React, { useState } from "react";
import {
  emailValidation,
  noSpaceOnChange,
  notEmptyValidation,
  numberValidation,
  phoneOnChange,
  phoneValidation,
  websiteValidation,
} from "../../validation/formValidation";
import FormInput from "../TechSupport/FormInput";
import { connect } from "react-redux";
import { postSales, formStatusPending, formStatusNull, formStatusCustom } from "../../actions/forms";
import FeedbackPrimary from "../../ui/feedback/FeedbackPrimary";
import axios from "../../api";

function Sale(props) {
  const { postSales, formStatusPending, formStatusNull, formStatus, formStatusCustom } = props;

  const [saleData, setSaleData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    site: "",
    country: "",
    clientType: "Dealer",
    referredBy: "Dealer",
    comments: "",
    isNewsletter: false
  });

  const [saleDataError, setSaleDataError] = useState({
    companyError: "",
    websiteError: "",
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    phoneError: "",
    countryError: ""
  });

  const removeInitialZero = (input) => {
    let output = input
    output = output.slice(1, output.length)
    for (const digit of output) {
      if (digit !== '0') {
        break
      }
      output = output.splice(1)
    }
    return output
  }

  const onChangeSaleData = (e) => {
    if (e.target.name === "site") {
      noSpaceOnChange(e.target.value) && setSaleData({ ...saleData, site: e.target.value });
    } else if (e.target.name === "email") {
      noSpaceOnChange(e.target.value) && setSaleData({ ...saleData, email: e.target.value });
    } else if (e.target.name === "phone") {
      phoneOnChange(e.target.value) && setSaleData({ ...saleData, phone: e.target.value });
    } else if (e.target.name === "isnewsletter") {
      setSaleData({ ...saleData, isNewsletter: !saleData.isNewsletter });
    } else if (e.target.name === "postal") {
      noSpaceOnChange(e.target.value) &&
        numberValidation(e.target.value) &&
        setSaleData({ ...saleData, postal: e.target.value });
    } else {
      setSaleData({ ...saleData, [e.target.name]: e.target.value });
    }
  };

  const onBlurSaleInput = (e) => {
    if (e.target.name === "site" && !websiteValidation(saleData.site)) {
      setSaleDataError({ ...saleDataError, websiteError: "Please enter a valid URL" });
    } else if (e.target.name === "email" && !emailValidation(saleData.email)) {
      setSaleDataError({ ...saleDataError, emailError: "Please enter a valid email" });
    } else if (e.target.name === "phone" && !phoneValidation(saleData.phone)) {
      setSaleDataError({ ...saleDataError, phoneError: "Phone number should be of 10 digits" });
    } else if (e.target.name === "company" && !notEmptyValidation(saleData.company)) {
      setSaleDataError({ ...saleDataError, companyError: "Company is required" });
    } else if (e.target.name === "firstName" && !notEmptyValidation(saleData.firstName)) {
      setSaleDataError({ ...saleDataError, firstNameError: "First name is required" });
    } else if (e.target.name === "lastName" && !notEmptyValidation(saleData.lastName)) {
      setSaleDataError({ ...saleDataError, lastNameError: "Last name is required" });
    } else if (e.target.name === "country" && !notEmptyValidation(saleData.country)) {
      setSaleDataError({ ...saleDataError, addressError: "Country is required" });
    }
  };

  const onSubmitValidation = () => {
    return (
      // websiteValidation(saleData.website) &&
      emailValidation(saleData.email) &&
      phoneValidation(saleData.phone) &&
      notEmptyValidation(saleData.company) &&
      notEmptyValidation(saleData.firstName) &&
      notEmptyValidation(saleData.lastName) &&
      notEmptyValidation(saleData.country) &&
      notEmptyValidation(saleData.site)
    );
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!onSubmitValidation()) {
      console.log("please fill the required feilds");
      return;
    }

    formStatusPending();

    try {
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(process.env.REACT_APP_SITE_KEY, { action: "submit" });

        const res = await axios.get("/api/Home/ValidateCaptcha", {
          params: {
            captcha: token,
          },
        });

        if (res.data) {
          await postSales(saleData);
          formStatusNull();
        } else {
          formStatusCustom(404);
          formStatusNull();
        }
      });
    } catch (error) {
      formStatusCustom(404);
      formStatusNull();
    }
  };

  return (
    <div className="flex flex-col justify-center items-center w-full">
      {/* <ListFreePrimary customClass="row-md-12 SaleSecondDiv"> */}
      <div className="flex flex-col w-full items-center">
        <h1 className="display-center-5 text-center">Fill out this form</h1>
        {/* form */}
        <form className="flex flex-col gap-10" style={{ marginTop: "20px" }} onSubmit={onSubmit}>
          <div className="flex flex-col gap-10 p-20 w-full max-w-screen-lg bg-white rounded-md hover:shadow-xl transition-all ease-in-out">
            <FormInput
              title="First Name"
              type="text"
              name="firstName"
              value={saleData.firstName}
              onChange={onChangeSaleData}
              required
              placeholder="Your First Name"
            />
            <FormInput
              title="Last Name"
              type="text"
              name="lastName"
              value={saleData.lastName}
              onChange={onChangeSaleData}
              onBlur={onBlurSaleInput}
              placeholder="your last name"
              required
              error={saleDataError.lastNameError}
            />
            <FormInput
              title="Phone Number"
              type="text"
              name="phone"
              value={saleData.phone}
              onChange={onChangeSaleData}
              onBlur={onBlurSaleInput}
              placeholder="555-555-5555"
              required
              error={saleDataError.phoneError}
            />
            <FormInput
              title="Email Address"
              type="text"
              placeholder="john@doe.com"
              name="email"
              value={saleData.email}
              onChange={onChangeSaleData}
              onBlur={onBlurSaleInput}
              required
              error={saleDataError.emailError}
            />
          </div>

          <div className="flex flex-col gap-10 p-20 w-full max-w-screen-lg bg-white rounded-md hover:shadow-xl transition-all ease-in-out">
            <FormInput
              title="Company Name"
              type="text"
              name="company"
              value={saleData.company}
              onChange={onChangeSaleData}
              onBlur={onBlurSaleInput}
              placeholder="Enter Name of Business"
              required
              error={saleDataError.companyError}
            />
            <FormInput
              title="Company Website Address"
              type="text"
              name="site"
              value={saleData.site}
              onChange={onChangeSaleData}
              onBlur={onBlurSaleInput}
              placeholder="www.companywebsite.com"
            // error={saleDataError.websiteError}
            />
            <FormInput
              title="Country/Region"
              name="country"
              value={saleData.country}
              onChange={onChangeSaleData}
              type="text"
              placeholder="enter country or region"
              error={saleDataError.countryError}
              required
            />
          </div>

          <div className="flex flex-col gap-10 p-20 w-full max-w-screen-lg bg-white rounded-md hover:shadow-xl transition-all ease-in-out">
            <div className="">
              <label>Are you interested in becoming a dealer or an end user? (select one)</label>
              <select
                className="form-control form-control-sm"
                name="clientType"
                value={saleData.clientType}
                onChange={onChangeSaleData}
              >
                <option value="Dealer">Dealer</option>
                <option value="End user">End user</option>
              </select>
            </div>
            <div className="">
              <label>May we ask who referred you? (select one)</label>
              <select
                className="form-control form-control-sm"
                name="referredBy"
                value={saleData.referredBy}
                onChange={onChangeSaleData}
              >
                <option value="Dealer">Dealer</option>
                <option value="End user">End User</option>
                <option value="Consultant">Consultant</option>
                <option value="Website">Website</option>
                <option value="Trade Show">Trade Show</option>
                <option value="Publication">Publication</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="">
              <textarea
                placeholder="How may we help you? (provide any additional information, questions, or comments)"
                className="pb-cmnt-textarea"
                id="text"
                name="comments"
                value={saleData.comments}
                onChange={onChangeSaleData}
                rows="5"
              ></textarea>
            </div>
            <div className="check">
              <input
                className="check-input"
                type="checkbox"
                name="isnewsletter"
                value={saleData.isNewsletter}
                onChange={onChangeSaleData}
                id="flexCheckDefault1"
              />
              <label className="check-label" htmlFor="flexCheckDefault1">
                Sign up for KanexPro product emails (you can unsubscribe at any time)
              </label>
            </div>
            <button className="btn btn-primary float-xs-right" type="submit">
              Submit
            </button>
          </div>

          <div>
          </div>
        </form>
      </div>
      {/* </ListFreePrimary> */}
      {formStatus && <FeedbackPrimary formStatus={formStatus} />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    formStatus: state.data.formStatus,
  };
};

export default connect(mapStateToProps, { postSales, formStatusPending, formStatusNull, formStatusCustom })(Sale);
