import React, { useState } from "react";
import { connect } from "react-redux";
import { postDealer, formStatusPending, formStatusNull, formStatusCustom } from "../../actions/forms";
import FeedbackPrimary from "../../ui/feedback/FeedbackPrimary";
import ListFreePrimary from "../../ui/list/ListFreePrimary";
import {
  emailValidation,
  noSpaceOnChange,
  notEmptyValidation,
  phoneOnChange,
  phoneValidation,
} from "../../validation/formValidation";
import FormInput from "../TechSupport/FormInput";
import axios from "../../api";

function DealerSignup(props) {
  const { postDealer, formStatusPending, formStatusNull, formStatus, formStatusCustom } = props;

  const [dealerForm, setDealerForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    inquiry: "",
    isNewsletter: false,
  });

  const [dealerError, setDealerError] = useState({
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    phoneError: "",
    companyError: "",
    inquiryError: "",
  });

  const onChangeData = (e) => {
    if (e.target.name === "isNewsletter") {
      setDealerForm({ ...dealerForm, isNewsletter: !dealerForm.isNewsletter });
    } else if (e.target.name === "email") {
      noSpaceOnChange(e.target.value) && setDealerForm({ ...dealerForm, email: e.target.value });
    } else if (e.target.name === "phone") {
      phoneOnChange(e.target.value) && setDealerForm({ ...dealerForm, phone: e.target.value });
    } else {
      setDealerForm({ ...dealerForm, [e.target.name]: e.target.value });
    }
  };

  const onBlurData = (e) => {
    switch (e.target.name) {
      case "email":
        if (!emailValidation(dealerForm.email)) {
          setDealerError({ ...dealerError, emailError: "Please enter a valid email" });
        } else {
          setDealerError({ ...dealerError, emailError: "" });
        }
        break;
      case "phone":
        if (!phoneValidation(dealerForm.phone)) {
          setDealerError({ ...dealerError, phoneError: "Phone number should be of 10 digits" });
        } else {
          setDealerError({ ...dealerError, phoneError: "" });
        }
        break;
      case "firstName":
        if (!notEmptyValidation(dealerForm.firstName)) {
          setDealerError({ ...dealerError, firstNameError: "First name is required" });
        } else {
          setDealerError({ ...dealerError, firstNameError: "" });
        }
        break;
      case "lastName":
        if (!notEmptyValidation(dealerForm.lastName)) {
          setDealerError({ ...dealerError, lastNameError: "Last name is required" });
        } else {
          setDealerError({ ...dealerError, lastNameError: "" });
        }
        break;
      case "company":
        if (!notEmptyValidation(dealerForm.company)) {
          setDealerError({ ...dealerError, companyError: "Company is required" });
        } else {
          setDealerError({ ...dealerError, companyError: "" });
        }
        break;
      case "inquiry":
        if (!notEmptyValidation(dealerForm.inquiry)) {
          setDealerError({ ...dealerError, inquiryError: "Inquiry is required" });
        } else {
          setDealerError({ ...dealerError, inquiryError: "" });
        }
        break;
      default:
        break;
    }
  };

  const onSubmitValidation = () => {
    if (
      notEmptyValidation(dealerForm.firstName) &&
      notEmptyValidation(dealerForm.lastName) &&
      notEmptyValidation(dealerForm.company) &&
      notEmptyValidation(dealerForm.inquiry) &&
      emailValidation(dealerForm.email) &&
      phoneValidation(dealerForm.phone)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!onSubmitValidation()) {
      return;
    }
    formStatusPending();
    try {
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(process.env.REACT_APP_SITE_KEY, { action: "submit" });

        const res = await axios.get("/api/Home/ValidateCaptcha", {
          params: {
            captcha: token,
          },
        });

        if (res.data) {
          setDealerForm({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            company: "",
            inquiry: "",
            isNewsletter: false,
          });
          setDealerError({
            firstNameError: "",
            lastNameError: "",
            emailError: "",
            phoneError: "",
            companyError: "",
            inquiryError: "",
          });
          await postDealer(dealerForm);
          setTimeout(() => {
            formStatusNull();
          }, 3000);
        } else {
          formStatusCustom(404);
          setTimeout(() => {
            formStatusNull();
          }, 3000);
        }
      });
    } catch (error) {
      formStatusCustom(404);
      setTimeout(() => {
        formStatusNull();
      }, 3000);
    }
  };

  return (
    <div className="DealerMainDiv">
      <div class="login d-flex align-items-center py-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-xl-12 mx-auto text-center">
              <h1 class="display-center-5">Fill out this form</h1>
              <form class="flex flex-col items-center gap-10" style={{ marginTop: "20px" }} onSubmit={onSubmit}>
                <div class="flex flex-col gap-10 p-20 w-full max-w-screen-lg bg-white rounded-md hover:shadow-xl transition-all ease-in-out">
                  <FormInput
                    title="First name"
                    name="firstName"
                    type="text"
                    placeholder="Enter first name*"
                    value={dealerForm.firstName}
                    onChange={onChangeData}
                    onBlur={onBlurData}
                    required="true"
                    error={dealerError.firstNameError}
                  />
                  <FormInput
                    title="Last name"
                    name="lastName"
                    type="text"
                    placeholder="Enter last name*"
                    value={dealerForm.lastName}
                    onChange={onChangeData}
                    onBlur={onBlurData}
                    required="true"
                    error={dealerError.lastNameError}
                  />
                  <FormInput
                    title="Email"
                    name="email"
                    type="text"
                    placeholder="Enter email*"
                    value={dealerForm.email}
                    onChange={onChangeData}
                    onBlur={onBlurData}
                    required="true"
                    error={dealerError.emailError}
                  />
                  <FormInput
                    title="Phone"
                    name="phone"
                    type="text"
                    placeholder="Enter phone*"
                    value={dealerForm.phone}
                    onChange={onChangeData}
                    onBlur={onBlurData}
                    required="true"
                    error={dealerError.phoneError}
                  />
                  <FormInput
                    title="Company"
                    name="company"
                    type="text"
                    placeholder="Enter company's name*"
                    value={dealerForm.company}
                    onChange={onChangeData}
                    onBlur={onBlurData}
                    required="true"
                    error={dealerError.companyError}
                  />
                  <div className="comment-container">
                    <textarea
                      placeholder="If Any Inquiry Ask Here..."
                      class="pb-cmnt-textarea"
                      name="inquiry"
                      rows="5"
                      value={dealerForm.inquiry}
                      onChange={onChangeData}
                      onBlur={onBlurData}
                    ></textarea>
                    {dealerError.inquiryError && <p className="invalid-data mt-1">{dealerError.inquiryError}</p>}
                  </div>
                  <div className="form-check text-left">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inputCheckbox"
                      name="isNewsletter"
                      checked={dealerForm.isNewsletter}
                      value={dealerForm.isNewsletter}
                      onChange={onChangeData}
                    />
                    <label className="form-check-label ml-4" htmlFor="inputCheckbox">
                      Yes, please send me latest information on KanexPro
                    </label>
                  </div>


                  <button class="btn btn-primary float-xs-right" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {formStatus && <FeedbackPrimary formStatus={formStatus} />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    formStatus: state.data.formStatus,
  };
};

export default connect(mapStateToProps, { postDealer, formStatusPending, formStatusNull, formStatusCustom })(
  DealerSignup
);
