import React from "react";
import { Helmet } from "react-helmet";
import PressInq from "./PressInq";
import SidebarSecondary from "../common/sidebar/SidebarSecondary";
import { sales } from "../common/partials/navData";
import "./PressInq.scss";

export default function PressInquiry({ location }) {
  return (
    <>
      <Helmet>
        <title>Press Inquiries</title>
        <meta name="description" content="Professional Audio Video components"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div>
        <div className="d-flex">
          <div className="d-none d-lg-block d-xl-block">
            <SidebarSecondary path={location.pathname} data={sales} title="Sales" />
          </div>

          <div className="press-inquiry">
            <h1 class="display-4">Press Inquiry</h1>
            <div className="info-container">
              <p>
                We appreciate your interest in the KanexPro brand. Please fill out the form below detailing any
                marketing or press opportunities you would like us to consider. We will contact you shortly.
              </p>
            </div>
            <PressInq />
          </div>
        </div>
      </div>
    </>
  );
}
