import React, { useState } from "react";
import { emailValidation, noSpaceOnChange, notEmptyValidation } from "../../../validation/formValidation";
import axios from "../../../api";
import { postFaq, formStatusNull, formStatusCustom, formStatusPending } from "../../../actions/forms";
import { connect } from "react-redux";

const FaqForm = ({ data, postFaq, formStatus, formStatusNull, formStatusCustom, formStatusPending }) => {
  const [faqData, setFaqData] = useState({
    name: "",
    email: "",
    question: "",
    isNewsletter: false,
  });

  const [faqError, setFaqError] = useState({
    nameError: "",
    emailError: "",
    questionError: "",
  });

  const onChangeData = (e) => {
    if (e.target.name === "name") {
      setFaqError({
        ...faqError,
        nameError: "",
      });
      setFaqData({ ...faqData, [e.target.name]: e.target.value });
    } else if (e.target.name === "email") {
      setFaqError({
        ...faqError,
        emailError: "",
      });

      noSpaceOnChange(e.target.value) && setFaqData({ ...faqData, [e.target.name]: e.target.value });
    } else if (e.target.name === "isNewsletter") {
      setFaqData({ ...faqData, isNewsletter: !faqData.isNewsletter });
    } else {
      setFaqError({
        ...faqError,
        questionError: "",
      });
      setFaqData({ ...faqData, [e.target.name]: e.target.value });
    }
  };

  const onBlurInput = (e) => {
    if (e.target.name === "name" && !notEmptyValidation(faqData.name)) {
      setFaqError({ ...faqError, nameError: "Please enter your name" });
    }
    if (e.target.name === "email" && !emailValidation(faqData.email)) {
      setFaqError({ ...faqError, emailError: "Please enter a valid email" });
    }
    if (e.target.name === "question" && !notEmptyValidation(faqData.question)) {
      setFaqError({ ...faqError, questionError: "Please enter your question" });
    }
  };

  const onSubmitValidation = () => {
    if (!notEmptyValidation(faqData.name) && !emailValidation(faqData.email) && !notEmptyValidation(faqData.question)) {
      return false;
    } else {
      return true;
    }
  };

  const onSubmitFaq = async (e) => {
    e.preventDefault();
    if (!onSubmitValidation()) {
      return;
    }

    formStatusPending();

    const body = {
      question: faqData.question,
      answer: faqData.question,
      fkpid: 0,
      email: faqData.email,
      name: faqData.name,
      sku: data.sku,
      kpro: true,
    };

    try {
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(process.env.REACT_APP_SITE_KEY, { action: "submit" });

        const res = await axios.get("/api/Home/ValidateCaptcha", {
          params: {
            captcha: token,
          },
        });

        if (res.data) {
          setFaqData({
            name: "",
            email: "",
            question: "",
            isNewsletter: false,
          });
          setFaqError({
            nameError: "",
            emailError: "",
            questionError: "",
          });
          await postFaq(body);
          setTimeout(() => {
            formStatusNull();
          }, 3000);
        } else {
          formStatusCustom(404);
          setTimeout(() => {
            formStatusNull();
          }, 3000);
        }
      });
    } catch (error) {
      formStatusCustom(404);
      setTimeout(() => {
        formStatusNull();
      }, 3000);
    }
  };

  return (
    <form className="faq-form" onSubmit={onSubmitFaq}>
      <h1 className="display-center-5">Ask your question</h1>
      <div className="form-group">
        {/* <label htmlFor="inputName">
          Name {!notEmptyValidation(faqData.name) && <span className="red">(Required)</span>}
        </label> */}
        <input
          type="text"
          className="form-control"
          id="inputName"
          placeholder="Enter your name "
          name="name"
          value={faqData.name}
          onChange={onChangeData}
          onBlur={onBlurInput}
          required
        />
        {faqError.nameError && <p className="invalid-data mt-1">{faqError.nameError}</p>}
      </div>
      <div className="form-group">
        {/* <label htmlFor="inputEmail">
          Email {!emailValidation(faqData.email) && <span className="red">(Required)</span>}
        </label> */}
        <input
          type="text"
          className="form-control"
          id="inputEmail"
          placeholder="Enter your email"
          name="email"
          value={faqData.email}
          onChange={onChangeData}
          onBlur={onBlurInput}
          required
        />
        {faqError.emailError && <p className="invalid-data mt-1">{faqError.emailError}</p>}
      </div>

      <div className="form-group">
        {/* <label htmlFor="inputTextarea">
          Ask any question {!notEmptyValidation(faqData.question) && <span className="red">(Required)</span>}
        </label> */}
        <textarea
          className="form-control"
          id="inputTextarea"
          rows="3"
          placeholder="Type your question here... "
          name="question"
          value={faqData.question}
          onChange={onChangeData}
          onBlur={onBlurInput}
          required
        ></textarea>
        {faqError.questionError && <p className="invalid-data mt-1">{faqError.questionError}</p>}
      </div>
      <div className="form-group">
        <div className="form-check text-left">
          <input
            className="form-check-input"
            type="checkbox"
            id="inputCheckbox"
            name="isNewsletter"
            checked={faqData.isNewsletter}
            value={faqData.isNewsletter}
            onChange={onChangeData}
          />
          <label className="form-check-label ml-4" htmlFor="inputCheckbox">
            Yes, please send me latest information on KanexPro
          </label>
        </div>
      </div>
      <div className="form-group"></div>

      <button size={1} type="submit" className="btn btn-primary">
        Submit
      </button>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    formStatus: state.data.formStatus,
  };
};

export default connect(mapStateToProps, { postFaq, formStatusNull, formStatusCustom, formStatusPending })(FaqForm);
