import React, { useState } from "react";
import { connect } from "react-redux";
import ListFreePrimary from "../../ui/list/ListFreePrimary";
import { emailValidation, noSpaceOnChange, notEmptyValidation } from "../../validation/formValidation";
import FormInput from "../TechSupport/FormInput";
import { postNewsletter, formStatusPending, formStatusNull, formStatusCustom } from "../../actions/forms";
import FeedbackPrimary from "../../ui/feedback/FeedbackPrimary";
import axios from "../../api";

function NewsLetterSignup(props) {
  const { postNewsletter, formStatusPending, formStatusNull, formStatus, formStatusCustom } = props;

  const [nlForm, setNlForm] = useState({
    name: "",
    email: "",
  });

  const [nlError, setNlError] = useState({
    nameError: "",
    emailError: "",
  });

  const onChangeData = (e) => {
    if (e.target.name === "name") {
      setNlForm({ ...nlForm, name: e.target.value });
    } else {
      noSpaceOnChange(e.target.value) && setNlForm({ ...nlForm, email: e.target.value });
    }
  };

  const onBlurInput = (e) => {
    if (e.target.name === "name" && !notEmptyValidation(nlForm.name)) {
      setNlError({ ...nlError, nameError: "Name is required" });
    }
    if (e.target.name === "email" && !emailValidation(nlForm.email)) {
      setNlError({ ...nlError, emailError: "Email is required" });
    }
  };

  const onSubmitValidation = (e) => {
    if (notEmptyValidation(nlForm.name) && emailValidation(nlForm.email)) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!onSubmitValidation()) {
      return;
    }
    formStatusPending();
    try {
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(process.env.REACT_APP_SITE_KEY, { action: "submit" });

        const res = await axios.get("/api/Home/ValidateCaptcha", {
          params: {
            captcha: token,
          },
        });

        if (res.data) {
          setNlForm({
            name: "",
            email: "",
          });
          setNlError({
            nameError: "",
            emailError: "",
          });
          await postNewsletter(nlForm);
          setTimeout(() => {
            formStatusNull();
          }, 3000);
        } else {
          formStatusCustom(404);
          setTimeout(() => {
            formStatusNull();
          }, 3000);
        }
      });
    } catch (error) {
      formStatusCustom(404);
      setTimeout(() => {
        formStatusNull();
      }, 3000);
    }
  };
  return (
    <div className="NewsLetterMainDiv">
      <ListFreePrimary customClass="row-md-12 NewsLetterSecondDiv">
        <div class="login d-flex align-items-center py-5">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-xl-12 mx-auto text-center">
                <h1 class="display-center-5">Fill out this form</h1>
                <form class="form-card" style={{ marginTop: "20px" }} onSubmit={onSubmit}>
                  <div class="row justify-content-center text-left mb-2">
                    <FormInput
                      name="name"
                      value={nlForm.name}
                      onChange={onChangeData}
                      onBlur={onBlurInput}
                      type="text"
                      placeholder="Enter your name"
                      required
                      error={nlError.nameError}
                    />
                    <FormInput
                      name="email"
                      value={nlForm.email}
                      onChange={onChangeData}
                      onBlur={onBlurInput}
                      type="text"
                      placeholder="Enter your email"
                      required
                      error={nlError.emailError}
                    />
                  </div>
                  <div className="NewsLetterBtn">
                    <button class="btn btn-primary d-flex justify-content-center" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ListFreePrimary>
      {formStatus && <FeedbackPrimary formStatus={formStatus} />}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    formStatus: state.data.formStatus,
  };
};

export default connect(mapStateToProps, { postNewsletter, formStatusPending, formStatusNull, formStatusCustom })(
  NewsLetterSignup
);
